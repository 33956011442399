import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout/layout"
import PageBanner from "../components/banners/pageBanner"

const NotFoundPage = () => (
	<Layout>
		<article>
			<PageBanner 
				title={`Not Found`}
				description={`Page Not Found`}
			/>
			<div id="article-content" className="entry-content page-content">
				<section className="content light">
					<div className="constrained is-narrow">
						<p>This page doesn't exist. Return to <Link to={`/`}>home page</Link>, <Link to={`/`}>view some of my work</Link>, or <Link to={`/`}>get in touch</Link></p>
						<p></p>
					</div>
				</section>
			</div>
		</article>
	</Layout>
)

export default NotFoundPage
